import { useState } from 'react';


export default function useForm(defaults) {
  const [values, setValues] = useState(defaults);

  function updateValues(e) {
    // check if its anumber and convert
    let value = e.target.value;
    if(e.target.type === 'number') {
      value = parseInt(value);
    }
    setValues({
      //copy the existing values into it

      ...values,
      //update the new values that changed
      [e.target.name]: value,
    });

  }


  return { values,
           updateValues, };
}
