import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import barbed from '../assets/images/barbed.png';


const GalleryStyles = styled.div`
 .barbed {
  position: fixed;
  max-width: 1024px;

  top: 20rem;


   @media(max-width: 420px) {
   top: 17rem;



}}
   display: flex;
   flex-wrap: wrap;
   margin-bottom: 5rem;
    margin-top: 3rem;
    background-size: contain;
    background-repeat: round;
    background-color: rgba(0,0,0,0.5);

   .gallery-image-wrapper {
     width: 40%;
     margin: 5rem;

   @media(max-width: 420px) {
     width: 100%;
     margin: 2rem 4rem;


  }
}


`;




function SingleImage({key,  galleryImage, index}) {
  return (
    <div className="gallery-image-wrapper fadeIn">
        <Img key={key + index} fluid={galleryImage.image.asset.fluid} alt={galleryImage.name} className="gallery-image" />
    </div>
      );
}

export default function GalleryList({ galleryImages }) {
    return (
      <GalleryStyles>
<img src={barbed} className="barbed"/>
          {galleryImages.map((image, index) => (
            <SingleImage key={ image.id } galleryImage={image} index={index} />
         ))}
        </GalleryStyles>
    );
}
