import React, { useState } from 'react';
import { grapql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import GalleryList from '../components/GalleryList';
import background from '../assets/images/no-hope-no-fear.png';
import maiaWork from '../assets/images/maiawork.png';
import maiaWorkWhite from '../assets/images/elipageblack.png';
import maiaContact from '../assets/images/maiacontact.png';
import maiaContactWhite from '../assets/images/maiacontactwhite.png';
import maiaFlash from '../assets/images/maiaflash.png';
import maiaFlashWhite from '../assets/images/maiaflashwhite.png';
import maiaAbout from '../assets/images/maiaabout.png';
import maiaAboutWhite from '../assets/images/maiacopywhite.png';
import spiderRope from '../assets/images/spider-rope.png';
import spiderWeb from '../assets/images/spider-web.png';
import instagram from '../assets/images/instagram.png';
import barbed from '../assets/images/barbed.png';
import useForm from '../utils/useForm';
import useContact from '../utils/useContact';

const MainHeadingStyles = styled.div`
  background: black;
  font-size: 7rem;
  margin: 0 auto;
`;

const ContactHeaderStyles = styled.div`
  .contact {
    background: black;
    margin: 3rem auto;
    width: 17em;
  }

  .contact-text {
    margin-top: 6rem;
    text-align: center;
  }

  .name-field,
  .email-field {
    margin: 1em 0;
    width: 30rem;
    height: 2em;
    background-color: black;
    color: white;
    outline: white;
    border: 3px white solid;
  }
  .submit {
    text-align: center;
  }
  .submit-button {
    background-color: black;
    color: white;
    padding: 1rem 6rem;
    border: 3px white solid;
    margin: 0 auto;
  }
  .contact-field {
    background-color: black;
    color: white;
    border: 3px white solid;
    outline: white;
    height: 10em;
    margin: 1em 0;
    width: 30rem;
  }

  .ig-logo {
    width: 3rem;
  }
`;

const AboutStyles = styled.div`
  display: flex;

  @media(max-width: 420px) {
  flex-direction: column;
  }
  .about-text {
  width 50rem;
  font-size: 3.3rem;

  @media(max-width: 420px) {
  width: 360px;
  margin: 0 2rem;
  }
}
  .about-image {
  width: 40rem;
  margin-left: 10rem;

 @media(max-width: 420px) {
  width: 350px;
  margin:0 3rem;
  }
  }

  .left {
  text-align: end;
  margin-top: 10rem;
  }
`;

function About({ left, right }) {
  return (
    <AboutStyles>
      <div className="about-text">
        <p>Maia Forster is a Berlin based tattoo artist from Argentina.</p>
        <p className="left">
          Maia specializes in black work tattoos, flash and custom designs,
          heading towards traditional designs with a gothic punk flare.
        </p>
      </div>
      <div className="about-image">{right}</div>
    </AboutStyles>
  );
}

const WorkStyles = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
function Work({ left, right }) {
  return (
    <div className="header">
      <div className="left" style={{ visibility: 'hidden' }}>
        {' '}
        ${left}{' '}
      </div>
      <div className="right">
        <iframe
          src="https://player.vimeo.com/video/497741172?color=ffffff&title=0&byline=0&portrait=0"
          className="video"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
        <script src="https://player.vimeo.com/api/player.js" />
      </div>
    </div>
  );
}

function Contact() {
  const { values, updateValues } = useForm({
    name: '',
    email: '',
    contact: '',
  });

  return (
    <ContactHeaderStyles>
      <div className="contact">
        <div className="contact-text">
          <a href="https://www.instagram.com/maia_forster/">
            <p>
              See more on instagram...{' '}
              <img className="ig-logo" src={instagram} />
            </p>
          </a>
          <p>maiaforstertattoo@gmail.com </p>
        </div>
      </div>
    </ContactHeaderStyles>
  );
}

function Gallery({ activePage, flash, gallery, galleryImages }) {
  return (
    <>
      <GalleryList
        galleryImages={activePage === 'FLASH' ? flash : galleryImages}
      />
      <WorkStyles>
        <iframe
          src="https://player.vimeo.com/video/497741172?color=ffffff&title=0&byline=0&portrait=0"
          className="video"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </WorkStyles>
      <script src="https://player.vimeo.com/api/player.js" />
    </>
  );
}

function MainPage({
  activePage,
  flash,
  gallery,
  galleryImages,
  description,
  profile,
}) {
  let DisplayPage = (
    <Gallery
      activePage={activePage}
      flash={flash}
      gallery={gallery}
      galleryImages={galleryImages}
    />
  );

  switch (activePage) {
    case 'ABOUT':
      const right = (
        <Img
          fluid={profile.image.asset.fluid}
          alt="Profile picture of Maia Forster"
          className="profile-image"
        />
      );
      DisplayPage = <About left={description.description} right={right} />;

      break;
    case 'FAQ':
      DisplayPage = <Contact />;
      break;
    case 'WORK':
      DisplayPage = (
        <Gallery
          activePage={activePage}
          flash={flash}
          gallery={gallery}
          galleryImages={galleryImages}
        />
      );
      break;
    case 'FLASH':
      DisplayPage = <GalleryList galleryImages={flash} />;
      break;
    default:
      DisplayPage = (
        <Gallery
          activePage={activePage}
          flash={flash}
          gallery={gallery}
          galleryImages={galleryImages}
        />
      );
  }

  return <>{DisplayPage}</>;
}

const PageHeaderStyles = styled.div`

 .barbed {
  position: fixed;
  max-width: 1024px;
  top: -3rem;
}

   background-image: url(${background});
   background-size: contain;

   .page-heading {

     font-size: 7rem;
     width: 44%;
     margin: 0 auto;
     padding-top: 3rem;

   @media(max-width: 420px) {
    font-size: 6.5rem;
    width: 86%;


}



  }
   }

  .menu {
   margin: 6rem 0;
   display: flex;

    @media(max-width: 420px) {
     height: 65px;
     position: fixed;
     z-index: 90;
     bottom: -65px;



  }

 }
  .menu-item {
   width: 25%;
 }


  .menu-item-text {
   width: fit-content;
   margin: 0 auto;
   font-size: 4rem;
   background: black;
   padding: 0 1rem;

  @media(max-width: 420px) {
     text-align: center;
     font-size: 2rem;
  }

  }
  .menu-item-text:hover {
   background-color: rgba(255,255,255,0.7);

}

  .menu-item-image {
    height: 4rem;
    vertical-align: middle;


  }

  .active > .menu-item-text {
    background-color: rgba(255,255,255,0.7);
    color: black;
  }
`;

function compareOrder(a, b) {
  return a.order - b.order;
}

export default function HomePage({ data }) {
  const [activePage, setActivePage] = useState('WORK');

  const galleryImages = data.gallery.nodes.sort(compareOrder);

  const flash = data.flash.nodes.sort(compareOrder);
  const headerImage = data.header.nodes[0];
  const description = data.description.nodes[0];
  const profile = data.person.nodes[0];
  const faq = data.faq.nodes[0];

  const headerText = data.headertext.nodes[0];
  const showWork = () => setActivePage('WORK');
  const showFlash = () => setActivePage('FLASH');
  const showAbout = () => setActivePage('ABOUT');
  const showFAQ = () => setActivePage('FAQ');
  const gallery = activePage === 'FLASH' || activePage === 'WORK';

  return (
    <div className="page-container">
      <PageHeaderStyles>
        <div className="page-heading fadeIn">Maia Forster</div>
        <div className="menu">
          <div
            className={activePage === 'WORK' ? 'menu-item active' : 'menu-item'}
            onClick={showWork}
          >
            <div className="menu-item-text">
              <img
                className="menu-item-image"
                src={activePage === 'WORK' ? maiaWorkWhite : maiaWork}
              />
              Work
            </div>
          </div>
          <div
            className={
              activePage === 'FLASH' ? 'menu-item active' : 'menu-item'
            }
            onClick={showFlash}
          >
            <div className="menu-item-text">
              <img
                className="menu-item-image"
                src={activePage === 'FLASH' ? maiaFlashWhite : maiaFlash}
              />
              Flash
            </div>
          </div>
          <div
            className={
              activePage === 'ABOUT' ? 'menu-item active' : 'menu-item'
            }
            onClick={showAbout}
          >
            <div className="menu-item-text">
              <img
                className="menu-item-image"
                src={activePage === 'ABOUT' ? maiaAboutWhite : maiaAbout}
              />
              About
            </div>
          </div>

          <div
            className={activePage === 'FAQ' ? 'menu-item active' : 'menu-item'}
            onClick={showFAQ}
          >
            <div className="menu-item-text">
              <img
                className="menu-item-image"
                src={activePage === 'FAQ' ? maiaContactWhite : maiaContact}
              />
              Contact
            </div>
          </div>
        </div>
      </PageHeaderStyles>
      <MainPage
        activePage={activePage}
        flash={flash}
        gallery={gallery}
        galleryImages={galleryImages}
        description={description}
        profile={profile}
      />
    </div>
  );
}

export const query = graphql`
  query {
    gallery: allSanityGallery(sort: { order: ASC, fields: _id }) {
      nodes {
        order
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    flash: allSanityFlash(sort: { order: ASC, fields: _id }) {
      nodes {
        order
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    header: allSanityHeader {
      nodes {
        headerImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    headertext: allSanityHeader {
      nodes {
        headerText
      }
    }

    person: allSanityPerson {
      nodes {
        image {
          asset {
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    description: allSanityPerson {
      nodes {
        description
      }
    }
    faq: allSanityFaq {
      nodes {
        question
        answer
      }
    }
  }
`;
